<template>
  <div id="my-player"></div>
</template>

<script>
import { mapMutations } from 'vuex';
import { Player } from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player-ui';

export default {
  name: 'Bitmovin',
  data() {
    return {
      container: false,
      player: false,
      config: {
        key: 'b3cdbd3e-4dc0-4809-a38d-36f78f7d4b40',
      },
    };
  },
  beforeMount() {
  },
  mounted() {
    this.createPlayer();
  },
  methods: {
    ...mapMutations(['appReady']),
    createPlayer() {
      this.appReady(true);
      this.container = document.getElementById('my-player');
      this.player = new Player(this.container, this.config);
      UIFactory.buildDefaultUI(this.player);
      // this.player = new window.bitmovin.player.Player(this.container, this.config);
      this.player.load({
        title: '360 test',
        description: 'New player test',
        hls: 'https://c8c03ae875f0413a87b8a320c3f998fd.msvdn.net/vod/j7kjXr8ICuDx/playlist.m3u8',
        vr: {
          contentType: 'single',
        },
        // hls: '//bitmovin-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
        // progressive: '//bitmovin-a.akamaihd.net/content/MI201109210084_1/MI201109210084_mpeg-4_hd_high_1080p25_10mbits.mp4',
      }).then(
        () => {
          console.log('Successfully created Bitmovin Player instance');
        },
        (reason) => {
          console.log(`Error while creating Bitmovin Player instance: ${reason}`);
        },
      );
    },
  },
};
</script>

<style>
  .bmpui-ui-watermark {
    display: none;
  }
</style>
